@import url("https://fonts.cdnfonts.com/css/circular-std");
* {
  font-family: "Circular Std", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  background-color: #f5f8ff;
}

.generator {
  width: 67%;
  border-right: 1px solid #a8c2fb;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.generator .wrapper {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.history {
  width: 33%;
}
.history__items {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  max-height: calc(100% - 55px);
}
.history__item {
  height: 64px;
  display: flex;
  padding: 0.5rem;
  border: 1px solid #a8c2fb;
  border-radius: 8px;
  align-items: center;
  gap: 0.5rem;
}
.history__item .code {
  height: 100%;
  aspect-ratio: 1/1;
}
.history__item .data {
  font-weight: 100;
}
.history__item .data .date {
  font-size: 18px;
  color: #062464;
}
.history__item .data .link {
  font-size: 14px;
  color: #69799c;
}
.history__item #download {
  margin-left: auto;
  width: 32px;
  height: 32px;
  font-size: 28px;
  color: #6893ee;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  cursor: pointer;
}

#qr-code {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #a8c2fb;
}
#qr-code .placeholder {
  opacity: 0.25;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.buttons button {
  border-radius: 24px;
  outline: none;
  border: none;
  padding: 0.75rem 0;
  width: 100%;
  font-size: 18px;
  color: white;
  background-color: #6893ee;
  white-space: nowrap;
  align-self: center;
  cursor: pointer;
  transition: 0.2s;
}
.buttons button:hover {
  background-color: #427cf8;
}
.buttons a {
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
  background-color: #6893ee;
  cursor: pointer;
  transition: 0.2s;
}
.buttons a:hover {
  background-color: #427cf8;
}
.buttons a.disabled {
  cursor: not-allowed;
  background-color: #a8c2fb;
}
.buttons a.disabled:hover {
  background-color: #a8c2fb;
}

h1 {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #00184d;
  margin: 0;
}
h1 .icon {
  width: 32px;
  border-radius: 5px;
}

label {
  color: #001e5f;
}

input {
  padding: 0.75rem;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #c5c6ca;
  background-color: #fafcff;
  transition: 0.2s;
  outline: none;
  color: #00184d;
}
input.error {
  border: 1px solid #fba8a8;
  -webkit-animation: shake 0.5s ease 0s 1 normal forwards;
          animation: shake 0.5s ease 0s 1 normal forwards;
}

input:focus {
  border: 1px solid #6893ee;
  box-shadow: 0 0 0 2px rgba(104, 146, 238, 0.1764705882);
}

.historyHeading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #a8c2fb;
  padding: 0.5rem 1rem;
}

#clear {
  font-size: 14px;
  color: #6893ee;
  font-weight: 400;
  cursor: pointer;
}

@media (max-width: 1060px) {
  body {
    width: 100%;
  }
  .generator,
.history {
    width: 50%;
  }
}
@media (max-width: 800px) {
  body {
    overflow: unset;
    overflow-y: auto;
    flex-direction: column;
    gap: 48px;
  }
  .generator,
.history {
    width: 100%;
    border-right: none;
  }
  .generator {
    position: relative;
  }
  .generator::after {
    content: "";
    width: 50%;
    height: 1px;
    background-color: #a8c2fb;
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
  }
  .history__items {
    overflow-y: unset;
    max-height: unset;
  }
}
@media (max-width: 365px) {
  h1 .icon {
    width: 76px;
    height: 76px;
  }
}
@-webkit-keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    transform: translateX(-5px);
  }
  20%, 40%, 60% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(3px);
  }
  90% {
    transform: translateX(-3px);
  }
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    transform: translateX(-5px);
  }
  20%, 40%, 60% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(3px);
  }
  90% {
    transform: translateX(-3px);
  }
}